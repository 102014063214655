@use "src/_bg.scss";

@use "src/_btn.scss";
@use "src/_theme.scss";

@use "src/_side.scss";
@use "src/_token.scss";
@use "src/_wallet.scss";
@use "src/_footer.scss";

.App {
  text-align: left;
  padding: 16px;

}

.world {
  z-index: 2;

}


body {
  @include theme.textFont;

}
strong, button, input {
  @include theme.altFont;
}


body {
  background: theme.$bg;
  color: theme.$fontColor;
  width: 100%;

  height: 100vh;

  margin: 0;

}





a, a:visited {
  text-decoration: none;
  &:hover {
    color: theme.$theme2;
  }
}







@include btn.Btn;

.navi {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;

  background: rgba(theme.$bg,0.8);
  padding: 8px 16px;
  box-sizing: border-box;

  border: 2px solid rgba(0,0,0,0.4);
  border-top-left-radius: 100vw;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}


.deli-container {
  border-bottom: 1px solid theme.$pink;
  
  max-width: 800px;
  margin: 0 auto 32px;
  p {
    margin-top: 8px;
    margin-bottom: 16px;
    background: rgba(theme.$bg,0.8);
    padding: 4px 8px;
    box-sizing: border-box;
    border: 2px solid rgba(black,0.4);
    filter: drop-shadow(2px 2px 2px black);
    border-radius: 2px;
  }

}

.connected--false .world {
  display: none;
}


@include token.Token;
@include wallet.Wallet;
@include footer.Footer;
.mmbtn {
  position: fixed;
  top: 16px;
  right: 16px;
  margin: 0;
  font-size: 20px;
}

.focus {
  padding: 32px 64px 64px;
  text-align: left;
  background: rgba(theme.$bg,0.6);
  border: 1px solid rgba(white, 0.4);
  filter: drop-shadow(2px 2px 2px black);
  border-radius: 4px;
  max-width: 1080px;
  margin: 256px 32px  16px;
  a { font-size: 24px;}
  a, p, h2 {filter: drop-shadow(2px 2px 2px black);}
  h2 {
    color: theme.$theme;
    font-size: 32px;
  }
}

h1, h2, h3 {
  @include theme.titleFont;

}

h2 {
   color: theme.$theme1;
  font-size: 24px;
  display: inline-block;
  line-height: 64px;
  margin: 0;
  text-align: left;
  filter: drop-shadow(2px 2px 2px black);
}


.farm {
  max-width: 640px;
  max-height: 640px;
  height: max(40vh,350px);
  width: max(40vh,350px);
  margin: 16px;
  position: relative;
  display: inline-block;
  border: 4px solid white;
  border-radius: 4px;
  filter: drop-shadow(2px 2px 2px black);
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  div {
    background: rgba(0,0,0,0.4);
    padding: 16px;
    position: absolute;
    bottom: 0;
    width: 60%;
    left: 0;
    text-align: left;
    border-top-right-radius: 24px;
    button {
      margin: 8px;
    }
  }
}


.farms--false {
  display: none;
}

.farmnav {
  button.btn {
    margin: 8px
  }
  span {
    padding: 2px;
    font-size: 24px;
    background: theme.$theme;
  }

}

.control-btns {
  position: fixed;
  top: 50px;
  button {
    margin: 2px 4px;
  }
}

.balances {
  text-align: right;
  filter: drop-shadow(2px 2px 2px black);
  span {
    margin: 0 8px;
    display: inline-block;
  }
  margin: 2px auto 16px;
  background: theme.$theme;
  position: fixed;
  bottom: 64px;
  left: -4px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 4px solid theme.$theme2;
  transition: 0.2s transform ease-in-out;
  &.balances--emissions {
    bottom: 104px;
    
    $w: 700px;
    $x1: $w - 94px;

    &.balances--false {
      transform: translateX(calc(100px - 100%));
    }
  }
  &.balances--burns {
    bottom: 144px;
    
    $w: 700px;
    $x1: $w - 94px;

    &.balances--false {
      transform: translateX(calc(110px - 100%));
    }
  }
  &.balances--true {
    transform: translateX(0);
  }
  $w: 640px;
  $x1: $w - 60px;
 
  &.balances--false {
    transform: translateX(calc(66px - 100%));
  }
 

}

span.click-span {
  cursor: pointer;
  font-weight: 600;
  color: theme.$theme1;
  &:hover{
    filter:drop-shadow(2px 2px 2px black);
  }
}

.pender {
  display: none;
  &.isPending--true {
    display: block;
  }
  padding-left: 16px;
  padding-top: 32px;
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: calc(100vh - 128px - 32px);
  background: theme.$theme;
  color: white;
  line-height: 64px;
  font-size: 32px;
}

.toggle-minters {
  position: fixed;
  left: 144px;
  top: 52px;
}

.mint-btns {
  display: block;
  transition: 0.2s transform ease-in-out;
  &.mint-btns--false {
    transform: translateX(100%);
  }
  position: fixed;
  right: 0;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  top: 100px;
  width: 226px;
  text-align: right;

  transform: translateX(2px);
  background: rgba(0,0,0,0.8);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 2px solid white;
  button {
    margin: 4px 0;
  }
}

.global-emissions {
  margin: 4px 0;
  span {
    margin: 0px 8px;
  }
}

.farms {
  margin-bottom: 128px;
  margin-left: 64px;
  margin-right: 64px;
  margin-top: 66px;
  border-left: 4px solid white;
}


  .bg {
    filter: brightness(100%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
    img {

      object-fit: cover;
      width: 100%;
      height: 100%;

    }
  }


.header {
  background: #a09d76;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  padding-left: 16px;
  filter:drop-shadow(2px 2px 2px black);
}

@media (max-width: 800px) and (orientation: portrait) {
  .App {
    padding-left: 8px;
    padding-right: 8px;
  }
  .farms {
    margin-left: 8px;
    margin-right: 8px;
    border-left: none;
    margin-bottom: 256px;
  }
  .farm {
    margin: 4px 0;
    width: calc(100vw - 16px - 8px - 16px);
    height: calc(100vw - 16px - 8px - 16px);
  }
  .mmbtn {
    top: calc(100vh - 48px);
  }
  .footer.isOn--false button{
    top: -80px;
  }
  h2 {
    font-size: 16px;
  }
  .balances {
    width: 100vw;
    height: 48px;
  }
  .balances.balances--emissions {
    bottom: 128px;

  }
}